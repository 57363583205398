export const LOGIN = '/';
export const SIGNUP = '/signup';
export const PRIVATE = '/private';

export const LOGOUT = '/private/logout';

export const PRESTAMO = '/prestamo';
export const PRESTAMO_SOLICITUD = '/prestamo/solicitud';
export const PRESTAMO_VERIFICACION = '/prestamo/verificacion';



export const VERIFICAR_CORREO = '/verificar/verificar-correo';
export const VERIFICAR_TELEFONO = '/verificar/verificar-telefono';

export const SOLICITAR_PRESTAMO = '/solicitar-prestamo';
export const TU_HISTORIAL = '/solicitar-prestamo/tu-historial';